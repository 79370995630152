
export default {
  data() {
    return {
      menuOpen: false,
      headerBottom: false,
      searchOpen: false,
      homepage: false,
      activeNavElement: null,
    };
  },
  watch: {
    $route() {
      this.closeMenu();
      this.closeSearch();
    },
  },

  destroyed() {
    if (process.browser) {
      window.removeEventListener("scroll", this.getPageYOffset);
    }
  },
  mounted() {
    if (process.browser) {
      window.addEventListener("scroll", this.getPageYOffset);
    }
  },

  methods: {
    setActiveElement(el) {
      this.activeNavElement = el;
    },

    toggleMenu(e) {
      const el = e.currentTarget;
      if (el) {
        this.menuOpen = el.classList.contains("menuOpen");
      }

      const element = document.documentElement;
      if (element) {
        if (this.menuOpen) {
          element.classList.add("overflow-hidden");
        } else {
          element.classList.remove("overflow-hidden");
        }
      }
    },
    closeMenu() {
      this.menuOpen = false;
      const openClass = document.getElementsByClassName("menuOpen");
      if (openClass && openClass[0]) {
        openClass[0].classList.remove("menuOpen");
      }

      const element = document.documentElement;
      if (element) {
        element.classList.remove("overflow-hidden");
      }
    },

    getPageYOffset() {
      if (window.pageYOffset < 20) {
        this.headerBottom = false;
      } else this.headerBottom = true;
    },

    openSearch() {
      this.searchOpen = true;

      if (this.menuOpen) {
        this.closeMenu();
      }

      const element = document.documentElement;
      if (element) {
        element.classList.add("overflow-hidden");
      }

      if (document.getElementById("searchInput")) {
        setTimeout(() => {
          document.getElementById("searchInput").focus();
        }, 300);
      }
    },

    closeSearch() {
      this.searchOpen = false;

      const element = document.documentElement;
      if (element) {
        element.classList.remove("overflow-hidden");
      }
    },

    scrollToSection(link) {
      if (this.menuOpen) {
        this.closeMenu();
      }

      if (this.$route.path === "/" || this.$route.path === "pocetna") {
        location.href = "#";
        location.href = link;
      } else {
        this.$router.push("/");

        setTimeout(() => {
          location.href = "#";
          location.href = link;
        }, 2000);
      }
    },
  },
};
