
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    link: {
      type: [String, Object],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    whiteButton: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    buttonStyle() {
      let classes;

      if (this.disabled) {
        classes =
          "cursor-not-allowed text-secondary-gray-700 bg-secondary-gray-400 border-transparent";
        return classes;
      }

      if (this.whiteButton) {
        classes =
          "md:hover:text-secondary-gray-100 bg-white md:hover:bg-primary border-primary";
      } else {
        classes =
          "bg-secondary-gray-100 md:hover:bg-secondary-gray-200 border-secondary-gray-100";
      }

      return classes;
    },
  },
};
