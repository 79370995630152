
export default {
  data() {
    return {
      newLine: null,
    };
  },

  mounted() {
    setTimeout(() => {
      this.newLine = this.$checkIfNewLine(this.id);
    }, 300);
  },
  methods: {
    async routerPush(tagTitle) {
      await this.$router.push({
        path: "tag-objave",
        query: {
          tag: tagTitle,
        },
      });
    },
  },
};
