import { render, staticRenderFns } from "./playlistComponent.vue?vue&type=template&id=0ad4d4e0"
import script from "./playlistComponent.vue?vue&type=script&lang=js"
export * from "./playlistComponent.vue?vue&type=script&lang=js"
import style0 from "./playlistComponent.vue?vue&type=style&index=0&id=0ad4d4e0&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIconsCircleArrow: require('/home/node/app/components/ui/icons/circle-arrow.vue').default,EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default,UiIconsPause: require('/home/node/app/components/ui/icons/pause.vue').default,UiIconsPlay: require('/home/node/app/components/ui/icons/play.vue').default,EditorListItem: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorListItem.js').default,EditorList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorList.js').default})
