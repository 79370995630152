
export default {
  data() {
    return {
      startAnimation: false,
    };
  },
  mounted() {
    setTimeout(() => {
      if (
        document.readyState === "complete" ||
        document.readyState === "interactive"
      ) {
        this.startAnimation = true;
      }
    }, 300);
  },
};
