import { render, staticRenderFns } from "./FooterElement.vue?vue&type=template&id=63d83bc2"
import script from "./FooterElement.vue?vue&type=script&lang=js"
export * from "./FooterElement.vue?vue&type=script&lang=js"
import style0 from "./FooterElement.vue?vue&type=style&index=0&id=63d83bc2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorImage: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorImage.js').default,ControllerFooter: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerFooter.js').default,EditorList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorList.js').default,EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default,UiIconsScrollArrow: require('/home/node/app/components/ui/icons/scrollArrow.vue').default})
