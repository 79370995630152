import { render, staticRenderFns } from "./PostView.vue?vue&type=template&id=0364e4fe"
import script from "./PostView.vue?vue&type=script&lang=js"
export * from "./PostView.vue?vue&type=script&lang=js"
import style0 from "./PostView.vue?vue&type=style&index=0&id=0364e4fe&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIconsPrevNextArrow: require('/home/node/app/components/ui/icons/prev-next-arrow.vue').default,ControllerPost: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerPost.js').default})
