
import * as vClickOutside from "v-click-outside";
export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      listActive: false,
      idArr: [],
      options: [
        {
          name: "duration",
          label: "Vrijeme trajanja pjesme",
          type: "text",
        },
        {
          name: "title",
          label: "Naziv pjesme",
          type: "text",
        },
        {
          name: "id",
          label: "ID pjesme",
          type: "text",
        },
        {
          name: "image",
          label: "Slika pjesme",
          type: "image",
          size: "48x48",
        },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.openPlaylist();
    }, 300);
  },

  methods: {
    openPlaylist() {
      if (window.innerWidth > 1699) {
        this.listActive = true;
      }
    },
    closePlaylist() {
      this.listActive = false;
    },
    playOrPauseVideo(id) {
      const iframe = document.getElementById(`iframe-${id}`);

      if (iframe && !iframe.getElementsByTagName("iframe").length) {
        const btn = iframe.getElementsByTagName("button")[0];

        btn.click();
      }

      if (this.idArr.includes(`iframe-${id}`)) {
        if (
          iframe &&
          iframe.getElementsByTagName("iframe") &&
          iframe.getElementsByTagName("iframe")[0] &&
          iframe.getElementsByTagName("iframe")[0].contentWindow
        ) {
          const video = iframe.getElementsByTagName("iframe")[0].contentWindow;
          video.postMessage(
            '{"event":"command","func":"' + "pauseVideo" + '","args":""}',
            "*"
          );
          this.idArr = [];
        }
      } else {
        this.idArr.push(`iframe-${id}`);
      }

      if (this.idArr && this.idArr.length) {
        this.idArr.forEach((item) => {
          const itemIframe = document.getElementById(item);
          if (
            itemIframe &&
            itemIframe.getElementsByTagName("iframe") &&
            itemIframe.getElementsByTagName("iframe")[0] &&
            itemIframe.getElementsByTagName("iframe")[0].contentWindow
          ) {
            const video =
              itemIframe.getElementsByTagName("iframe")[0].contentWindow;

            if (item !== `iframe-${id}`) {
              video.postMessage(
                '{"event":"command","func":"' + "pauseVideo" + '","args":""}',
                "*"
              );
            } else {
              video.postMessage(
                '{"event":"command","func":"' + "playVideo" + '","args":""}',
                "*"
              );
              this.idArr = [];
              this.idArr.push(`iframe-${id}`);
            }
          }
        });
      }
    },
  },
};
