
export default {
  data() {
    return {
      newLine: null,
    };
  },

  mounted() {
    setTimeout(() => {
      this.newLine = this.$checkIfNewLine(this.id);
    }, 300);
  },
};
