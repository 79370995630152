
export default {
  data() {
    return {
      options: [
        {
          name: "logo",
          label: "Logo",
          type: "image",
          size: "16x16",
        },
        {
          name: "link",
          label: "Poveznica",
          type: "link",
        },
      ],
    };
  },
};
