import Vue from "vue";

Vue.mixin({
  data() {
    return {
      defaultLanguage: "",
    };
  },
  mounted() {
    if (
      this.$application &&
      this.$application.i18n &&
      this.$application.i18n.defaultLocale
    ) {
      this.defaultLanguage = this.$application.i18n.defaultLocale;
    }
  },
  computed: {
    $homepageLink() {
      if (this.$i18n.locale === this.defaultLanguage) {
        return "/";
      } else {
        return `/${this.$i18n.locale}`;
      }
    },
  },
  methods: {
    $checkIfNewLine(id) {
      if (!id) return;

      let newLine = false;

      if (document.getElementById(id)) {
        const boxHeight = document.getElementById(id).offsetHeight;
        if (boxHeight > 48) {
          newLine = true;
        } else newLine = false;
      }
      return newLine;
    },
    $scrollToTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 200);
    },

    $formatDate(dateStr, short = false) {
      if (!dateStr) return;

      const date = new Date(dateStr);
      var region = "hr-HR";
      let options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      if (short) {
        options = {
          month: "short",
          day: "numeric",
        };
      }

      return date.toLocaleDateString(region, options);
    },
  },
});
